.poppins-regular {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .poppins-medium {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
  }

  .poppins-semibold {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  .poppins-bold {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
  }

  .flex_start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .flex_center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flex_wrapbetween {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .inlineflex_start {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .inlineflex_center {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flexdirection_col {
    flex-direction: column;
  }
  

  .upload-prescriptionwrap {
    width: 92.352%;
    margin: 14px auto 14px auto;

    @media only screen and (min-width: 1024px) {
      margin: 17px auto 15px auto;
    }
  }
  .new-option-header {
    display: flex;
    justify-content: space-between;
    button {
    }
    h4 {
      width: 237px;
      position: relative;
      font-size: 24px;
      font-weight: 500;
      color: #000;
      text-align: left;
      display: inline-block;
      opacity: 0.8;
    }
  }
  .button-shadow {
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  }
  #uploadsubmitbtn:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .savedPrescription__list + label .savedPrescription__default {
    display: block;
    cursor: pointer;
  }
  .savedPrescription__list + label .savedPrescription__selected {
    display: none;
    cursor: pointer;
  }

  .savedPrescription__list:checked + label .savedPrescription__default {
    display: none;
    cursor: pointer;
  }
  .savedPrescription__list:checked + label .savedPrescription__selected {
    display: block;
    cursor: pointer;
  }
  .upload-container {
    width: 100%;
    margin-top: 30px;
    position: relative;
    border-radius: 10px;
    background-color: rgba(0, 165, 255, 0.1);
    border: 3px dashed #00a5ff;
    box-sizing: border-box;
    height: 271px;
    text-align: center;
    padding-top: 92px;
    cursor: pointer;

    &:hover {
      background: #00a5ff33;
    }
  }
  .progress-container {
    width: 100%;
    margin-top: 15px;
    height: 231px;
    border: 3px dashed #00a5ff;
    border-radius: 10px;
    cursor: pointer;
    background: rgba(0, 165, 255, 0.1);

    &:hover {
      background: #00a5ff33;
    }

    .progress-barwrap {
      width: 81.132%;
      margin: 0 auto;
      padding-top: 61px;

      .progress-bar {
        height: 1rem;
        margin-top: 40px;
        background: #d9d9d9;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;

        .progress-div {
          transition-width: auto;
          transition-duration: 300ms;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          background: #00a5ff;
          height: 100%;
        }
      }
    }
  }

  .savedprescription-containerwrap {
    .savedprescription-container {
      width: 100%;
      .savedprescription-wrap {
        display: grid;
        height: 471px;
        row-gap: 1.25rem;
        overflow-y: scroll;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 1.25rem;

        &::-webkit-scrollbar {
          display: none;
        }

        @media only screen and (min-width: 1024px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
          column-gap: 1.75rem;
        }

        .savedprescription-list {
          background: #ffffff;
          border-radius: 15px;
          width: 8rem;
          height: 8rem;

          @media only screen and (min-width: 1024px) {
            width: 10.125rem;
            height: 10.125rem;
          }

          .savedprescription-imgdata {
            position: relative;
            .savedprescription-img {
              width: 8rem;
              height: 8rem;
              object-fit: cover;
              border-radius: 15px;

              @media only screen and (min-width: 1024px) {
                width: 10.125rem;
                height: 10.125rem;
              }
            }

            .savedprescription-imgcheck {
              position: absolute;
              top: 1rem;
              right: 1.125rem;

              .hidden {
                display: none;
              }
            }
          }
        }
      }
      .savedprescription-btns {
        margin-top: 10px;
        width: 100%;

        .upload-btnwrap {
          button {
            appearance: none;
            padding: 7.5px 9px;
            border-radius: 10px;
            border: 1px solid #9747ff;
            font-size: 14px;
            line-height: 21px;
            color: #9747ff;
            background: #ffffff;

            @media only screen and (min-width: 1024px) {
              padding: 15px 18px;
            }
          }
        }

        .action-btnwrap {
          gap: 18px;
          margin-top: 12px;

          @media only screen and (min-width: 1024px) {
            gap: 37px;
          }

          button {
            appearance: none;
            border: medium none;
            font-size: 14px;
            line-height: 21px;
            padding: 7px 22px;
            border-radius: 15px;

            @media only screen and (min-width: 1024px) {
              padding: 13px 44px;
            }
          }

          .cancel-btn {
            color: #000000;
            opacity: 0.6;
            background: #ffffff;
          }

          .delete-btn {
            color: #ffffff;
            background: #9747ff;

            &:disabled {
              cursor: not-allowed;
              opacity: 0.3;
            }
          }
        }
      }
    }
  }
  .actions-btn-sec-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 36px;
    margin-bottom: 16px;
    justify-content: flex-end;
    align-items: center;

    @media only screen and (min-width: 1024px) {
      justify-content: space-between;
      margin-top: 18px;
      margin-bottom: 8px;
    }

    .uploadprescriptions-leftbtns {
      gap: 12px;

      @media only screen and (min-width: 1024px) {
        gap: 24px;
      }
    }

    .uploadprescriptions-rightbtns {
      gap: 12px;
      margin-top: 6px;

      @media only screen and (min-width: 1024px) {
        gap: 24px;
        margin-top: 12px;
      }
    }
  }
  .saved-prescription-btn {
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #9747ff;
    box-sizing: border-box;
    width: 100%;
    padding: 7.5px 9px;
    color: #9747ff;
    white-space: nowrap;
    @media only screen and (min-width: 1024px) {
      padding: 15px 18px;
    }
  }
  .cancel-prescription-btn {
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #9747ff;
    box-sizing: border-box;
    width: 100%;
    padding: 7px 22px;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    opacity: 0.6;

    @media only screen and (min-width: 1024px) {
      padding: 13px 44px;
    }
  }
  .submit-prescription-btn {
    position: relative;
    border-radius: 10px;
    background-color: #9747ff;
    padding: 7px 22px;
    border: none;
    color: #fff;
    font-size: 18px;
    line-height: 27px;
    @media only screen and (min-width: 1024px) {
      padding: 13px 44px;
    }
  }
  .support-text-box-sec {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    position: relative;
    font-size: 16px;
    color: #000;
    white-space: pre-wrap;
    text-align: left;
    opacity: 0.6;
  }
  .remarks-prescription {
    margin-top: 10px;
    span {
      opacity: 0.6;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
    textarea {
      border: 2px solid #e0e0e0;
      width: 100%;
      margin: 0;
      padding: 0.5rem;
      font-size: 14px;
      line-height: 24px;
    }
  }
  #dropzone-file {
    display: none;
  }
  .checkmark__circle {
    transform-origin: center;
    stroke-dasharray: 1000;
    fill-opacity: 0;
    -webkit-animation: dash 3s ease-in-out, FillIn 3s 0.35s ease-in-out forwards;
    animation: dash 3s linear, FillIn 3s 0.35s ease-in-out forwards;
  }

  .checkmark__tick {
    stroke-dasharray: 1000;
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 3s 0.35s ease-in-out forwards infinite;
    animation: dash-check 3s 0.35s ease-in-out forwards infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes FillIn {
    from {
      fill-opacity: 0;
    }
    to {
      fill-opacity: 1;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
.custom-sidebar {
  position: fixed;
  top: 36px;
  right: -400px;
  /* Initially hide the sidebar off-screen */
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.custom-sidebar.open {
  right: 0;
  /* Slide the sidebar into view */
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px 0px 0px 0px;
  background: #D3EDFC;
}

.sidebar-header h3 {
  margin: 0;
  color: #313131;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.close-btn {
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
}

.sidebar-content {
  padding: 15px;
  overflow-y: auto;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* Behind the sidebar but above everything else */
}

.audiance-sec {
  /* padding: 20px; */
  background-color: #f8f9fa;
}

.custom-pagination .page-link {
  color: #5856d6;
  border: none;
  background-color: transparent;
}

.custom-pagination .page-item.active .page-link {
  background-color: #5856d6;
  border-color: #5856d6;
}

.btn-cls .create-aud {
  background-color: #5856d6;
  color: #fff;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.create-aud svg {
  margin-left: 5px;
}

.modal-content {
  border-radius: 15px;
  padding: 20px;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: #5856d6;
}

.upload-section {
  border: 2px dashed #5856d6;
  border-radius: 8px;
  padding: 30px;
  background-color: #f7f7ff;
  margin-bottom: 15px;
}

.upload-section p {
  font-size: 1rem;
  color: #5856d6;
  margin-top: 10px;
  font-weight: 500;
}

.text-success {
  color: #315E0E;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.popup-form .form-label {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bulk-upload-icon {
  font-size: 1.5rem;
  color: #3ac47d;
  margin-right: 5px;
}

.audience-details .form-control {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ced4da;
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-footer {
  border-top: none;
  padding-top: 0;
}

.modal-footer .btn-secondary {
  background-color: #f8f9fa;
  color: #6c757d;
  border: 1px solid #ced4da;
}

.modal-footer .btn-primary {
  background-color: #5856d6;
  border-color: #5856d6;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 8px;
}

.form-group label {
  font-weight: 500;
  color: #6c757d;
  font-size: 0.95rem;
}

.popup-form {
  display: flex;
  gap: 12px;
}

.btn-cls {
  text-align: -webkit-right;
}

.savenext {
  color: #F2FAFF !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border-radius: 5px !important;
  background: var(--Primary, #9747FF) !important;
  border: none !important;
}

.savebtn {
  border-radius: 0px 5px 5px 0px !important;
  background: #F9ECEC !important;
  color: #000 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border: none !important;
  position: absolute;
  right: 18px;
}

.code-section .form-control {
  color: #684B4B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.code-section .form-label {
  color: #684B4B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-link {
  background-color: transparent;
  border: none;
  text-decoration: none !important;
}

.message p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 183.333% */
  letter-spacing: -0.12px;
}

.check {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}

.check p {
  margin-top: 12px;
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 183.333% */
  letter-spacing: -0.12px;
}

.send-btn {
  color: var(--white, #FFF) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  /* 183.333% */
  letter-spacing: -0.12px !important;
  border-radius: 3px !important;
  background: var(--Primary, #9747FF) !important;
  border: none !important;
  margin-top: 20px !important;
  padding: 10px 30px !important;
  margin-bottom: 20px !important;
}

.message {
  border-radius: 5px;
  border: 1px solid #E4E3E3;
  background: var(--white, #FFF);
  padding: 12px;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .btn-cls {
    text-align: center;
    margin-top: 20px;
  }

  .pagination {
    justify-content: center;
  }

  .create-aud {
    width: 100%;
  }
  .row{
    display: block !important;
  }
  .tab-buttons{
    display: inline-flex;
    margin-top: 12px;
  }
  .tab-buttons button{
    padding: 9px !important;
  }
  .profile-pic{
    display: none;
  }
  .create-aud{
    margin-bottom:10px;
    margin-top: 0 !important;
  }
  .communication .nav-tabs{
    display: grid;
  }
}
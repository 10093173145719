.custom-modal .modal-title {
  color: #313131;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
}

.custom-modal .form-label {
  color: var(--dummy, #808080);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-top: 15px;
}

.custom-modal .form-control::placeholder {
  color: #ababab;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.custom-modal .form-control {
  border-radius: 5px;
  border: 0.5px solid #b8b8b8;
  background: var(--white, #fff);
  height: 40px;
}

.custom-modal .form-control,
.custom-modal select.form-control {
  border-radius: 5px;
  border: 0.5px solid #b8b8b8;
  background: var(--white, #fff);
  font-size: 14px;
  color: #000;
}

.custom-modal select.form-control {
  padding: 0.375rem 0.75rem;
}

.custom-modal select.form-control option {
  font-size: 14px;
  color: #313131;
}

.cancelbtn {
  border-radius: 2px;
  background: var(--white, #fff);
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.07);
  color: var(--dummy, #808080);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  border: #fff;
}

.save-btn {
  border-radius: 2px;
  background: var(--Primary, #9747ff);
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  padding: 11px 16px;
  color: var(--white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  border: #9747ff;
}

.whatsappbtn,
.mailbtn {
  display: flex;
  width: 100px;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  border-radius: 2px;
  background: var(--white, #fff);
  box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  color: var(--dummy, #808080);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff !important;
}

.edit-text {
  color: var(--dummy, #808080);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dropdown-menu {
  --bs-dropdown-min-width: auto !important;
}

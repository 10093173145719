.reminder-sent-content {
    text-align: center;
}

img {
    width: 160px;
}

.modal-title {
    color: #313131 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.reminder-sent-content p {
    color: var(--dummy, #808080) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}
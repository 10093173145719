.patient-info-section {
    display: flex;
    gap: 120px;
}

.patient-details {
    display: flex;
}

.modal-header {
    justify-content: space-between !important;
}

.modal-header .btn-close {
    position: absolute;
    top: 12px;
    right: 12px;
}

.details h2 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.26px;
}

.details .det-head {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    opacity: 0.7;
}

.details p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
    opacity: 0.8;
    margin-bottom: 8px;
}
.doctor-info-section .doc-name{
    color: #000;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.42px;
}
.doctor-info-section .doc-head{
    color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.42px;
opacity: 0.7;
}
.doctor-info-section p{
    color: #000;
    opacity: 0.8;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.42px;
margin-bottom:8px ;
}
.logo-image{
    width: 100px;
}
.body-head{
    color: #8A7878;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.42px;
}
.custom-table {
    width: 100%;
    overflow-x: auto;
  }
  .custom-table table{
    border: 1px solid #EBEBEB;
  }

  .custom-table .ant-table-thead {
    background: #dcf1fd;
    border-radius: 5px;
  }

  .custom-table .ant-table-thead > tr > th {
    background: #dcf1fd;
    border: none;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 10px 22px;
  }

  .custom-table .ant-table-tbody > tr > td {
    color: #756767;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 10px 22px;
  }

  .custom-table .ant-table-tbody > tr {
    margin: 10px 0;
    border-bottom: 1px solid #EBEBEB;;
  }
  .patient-cart{
    display: flex;
    gap:12px;
    margin-top: 12px;
    justify-content: end;
    align-items: baseline;
  }
  .patient-cart p{
    color: #000;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.36px;
  }
  .patient-cart button{
    border-radius: 5px;
background: var(--Primary, #9747FF);
display: inline-flex;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
color: var(--white, #FFF);
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.36px;
border: none;
  }
.patient-carttwo{
    display: flex;
    gap:12px;
    margin-top: 12px;
}
.address p{
    color: #000;
margin-bottom: 6px;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.3px;
}


@media (max-width: 768px){
    .patient-info-section {
        display: block;
    }
    .doctor-info-section{
        float: inline-end;
    }
}
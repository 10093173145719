.bodymodal {
    max-height: 300px;
    overflow-y: scroll;
}

.form-label {
    color: var(--dummy, #808080);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
}

.form-control::placeholder {
    color: #ABABAB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}

.form-check-label {
    color: #ABABAB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}
.timings-text{
    color: #313131;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.48px;
}
.week-name .form-label{
    color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.form-check-label{
    color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.btn-save{
    border-radius: 5px;
background: var(--Primary, #9747FF) !important;
border-color: #9747FF !important;
}
.card-body svg{
    margin-top: 80px !important;
}